var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('DashboardNavbar',{attrs:{"viewType":_vm.viewType,"viewTypeStatus":true,"activationTypesTokensOptions":_vm.getActivationTypeTokenOptions,"activationTypeTokens":_vm.activationTypeTokens,"activationTypeStatus":true,"btnAddTitle":_vm.$t('SystemComponentsHierarchies.add'),"btnAddName":'SystemComponentsHierarchyAdd',"btnAddStatus":_vm.hasPrivilegeAdd,"filterSheetName":'filterSheet',"filterSheetStatus":true},on:{"updateViewType":function($event){return _vm.updateViewType(_vm.modelName, $event)},"updateActivationTypeTokens":function($event){return _vm.changeActivationType($event)}}}),(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('div',{staticClass:"app-content pagination-with-content"},[(_vm.systemTypeToken == _vm.SYSTEM_TYPE.EducationalActivity)?_c('EducationalCategoryTabs'):_vm._e(),(_vm.systemTypeToken == _vm.SYSTEM_TYPE.Place)?_c('PlaceTabs'):_vm._e(),(_vm.systemTypeToken == _vm.SYSTEM_TYPE.GeographicalDistribution)?_c('GeographicalDistributionTabs'):_vm._e(),(_vm.systemTypeToken == _vm.SYSTEM_TYPE.JobGrade)?_c('JobGradeTabs'):_vm._e(),(_vm.hasData)?[(_vm.viewType == _vm.VIEW_TYPES.table)?_c('SystemComponentsHierarchyTable',{attrs:{"systemComponentsHierarchiesData":_vm.systemComponentsHierarchies.systemComponentsHierarchiesData,"defaultImg":_vm.systemComponentsHierarchies.systemComponentsHierarchy.defaultImg,"filterData":_vm.systemComponentsHierarchies.filterData,"modelName":_vm.modelName},on:{"setSystemComponentsHierarchyData":function($event){return _vm.systemComponentsHierarchies.systemComponentsHierarchy.fillData(
            $event
          )}}}):(_vm.viewType == _vm.VIEW_TYPES.cards)?_c('SystemComponentsHierarchyCards',{attrs:{"systemComponentsHierarchiesData":_vm.systemComponentsHierarchies.systemComponentsHierarchiesData,"defaultImg":_vm.systemComponentsHierarchies.systemComponentsHierarchy.defaultImg,"filterData":_vm.systemComponentsHierarchies.filterData,"modelName":_vm.modelName},on:{"setSystemComponentsHierarchyData":function($event){return _vm.systemComponentsHierarchies.systemComponentsHierarchy.fillData(
            $event
          )}}}):_vm._e(),_c('ActionsData',{attrs:{"actionsData":_vm.systemComponentsHierarchies.systemComponentsHierarchy}}),_c('SystemComponentsHierarchyInfo',{attrs:{"systemComponentsHierarchy":_vm.systemComponentsHierarchies.systemComponentsHierarchy}}),_c('SystemComponentsHierarchyDelete',{attrs:{"systemComponentsHierarchy":_vm.systemComponentsHierarchies.systemComponentsHierarchy},on:{"refresh":function($event){_vm.updateFilterData();
          _vm.getAllSystemComponentsHierarchies();}}}),_c('SystemComponentsHierarchyChangeActivationType',{attrs:{"systemComponentsHierarchy":_vm.systemComponentsHierarchies.systemComponentsHierarchy},on:{"refresh":function($event){_vm.updateFilterData();
          _vm.getAllSystemComponentsHierarchies();}}}),_c('CustomBottomSheetQRCode',{attrs:{"refName":'SystemComponentsHierarchyQRCode',"code":_vm.systemComponentsHierarchies.systemComponentsHierarchy.fullCode,"codeTitle":_vm.$t('SystemComponentsHierarchies.code'),"name":_vm.systemComponentsHierarchies.systemComponentsHierarchy
            .systemComponentsHierarchyNameCurrent,"nameTitle":_vm.$t('SystemComponentsHierarchies.name'),"nameIcon":'systemComponentsHierarchies.svg'}})]:_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):_vm._e(),(_vm.hasData)?_c('CustomPagination',{attrs:{"paginationData":_vm.systemComponentsHierarchies.filterData},on:{"changePagination":function($event){return _vm.changePagination($event)}}}):_vm._e(),_c('SystemComponentsHierarchyFilter',{attrs:{"theFilterData":_vm.systemComponentsHierarchies.filterData},on:{"search":function($event){return _vm.search($event)}}}),_c('SystemComponentsHierarchyAdd',{attrs:{"systemComponentsHierarchy":_vm.systemComponentsHierarchies.systemComponentsHierarchy,"systemTypeToken":_vm.systemTypeToken,"modelName":_vm.modelName},on:{"refresh":function($event){_vm.updateFilterData();
        _vm.getAllSystemComponentsHierarchies();}}}),_c('SystemComponentsHierarchyUpdate',{attrs:{"systemComponentsHierarchy":_vm.systemComponentsHierarchies.systemComponentsHierarchy,"systemTypeToken":_vm.systemTypeToken,"modelName":_vm.modelName},on:{"refresh":function($event){_vm.updateFilterData();
        _vm.getAllSystemComponentsHierarchies();}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }